body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.circle {
    width: 30px;
    height: 30px;
    border: 2px solid black;
    background-color: #454d55;
    color: white;
    line-height: 26px; /* Adjust this value to vertically center the text */
  }

.color-nav {
  background-color: rgb(24, 22, 82);
}

.hiddenCard {
  display: none;
}

.mycol {
  margin-top: 10px;
  min-height: 550px;
}
.mynav {
  margin-left: 10px;
}

.mycard {
  margin-top: 100px;
  font-family: "Lucida Console", Courier, monospace;
}

.pen-text{
  bottom-margin:10px;
  font-size:10px;
}
.myescudo-home{
  height: 50px;
  width: 50px;
  margin-top: -10px;
  margin-bottom: 10px;
  margin-left: 60%;

}
.myshort{
  height: 60px;
  width: 70px;
  margin-top: -10px;
  margin-bottom: 10px;
  text-align: center;
}



.myescudo-away{
  height: 50px;
  width: 50px;
  margin-top: -10px;
  margin-bottom: 10px;
margin-left: -60%;
}
.myescudo-away-main{
  height: 150px;
  width: 150px;
}
.my-pelota{
  height: 11px;
  width: 11px;
  margin-right: 2px;
  margin-bottom: 3px;
}
.my-own_goal{
  height: 11px;
  width: 11px;
  margin-right: 2px;
  margin-bottom: 3px;
}
.my-red{
  height: 10px;
  width: 8px;
  margin-right: 2px;
  margin-bottom: 3px;

}
.my-yellow{
  height: 10px;
  width: 8px;
  margin-right: 2px;
  margin-bottom: 3px;

}

.my-penalty{
  height: 11px;
  width: 18px;
  margin-right: 2px;
  margin-bottom: 3px;

}
.my-missed{
  height: 14px;
  width: 18px;
  margin-right: 2px;
  margin-bottom: 3px;

}

.mytext{
  font-size: 10px;
  margin-top: 20px;
}

.mycardtext{
  font-size: 16px;
  margin-top: 20px;
}




.mytitle{
  font-size: 12px;
}

.mytitlepartidos{
  font-size: 22px;
  font-wight: bold;
}

.item1 {
    margin-bottom: 10px;
    border-radius: 4px;
    background-color: #F9F9F9;
    box-shadow: 0 8px 24px -16px rgba(0,0,0,0.5);
    padding: 16px;
    font-size: 16px;
    cursor: pointer;
    width: 20%;
}

.list-escudo{
height: 20px;
  width: 20px;
}
.list-bandera{
height: 13px;
  width: 20px;
}

.pitch-escudo-home{
height: 40px;
  width: 40px;
}
.pitch-escudo-away{
height: 40px;
  width: 40px;
}



.list-video{
height: 17px;
  width: 17px;
}

.link-name{
color: aliceblue;

}

.myescudo-away2{
  height: 180px;
  width: 180px;
  margin-top: -10px;
  margin-bottom: 10px;
margin-left: -48%;
}

.myescudo-home2{
  height: 180px;
  width: 180px;
  margin-top: -10px;
  margin-bottom: 10px;
  margin-left: 25%;

} 
.myresultado{
margin-top: 50px;
font-size: 30px;

}


@media (max-width: 1200px) {


.myresultado{
margin-top: 20px;
font-size: 20px;

}
}

@media (max-width: 1200px) {
.myescudo-home2{
  height: 100px;
  width: 100px;
  margin-top: -10px;
  margin-bottom: 10px;
  margin-left: 32%;

} 
}

@media (max-width: 1200px) {

.mycardtext{
  font-size: 10px;
  margin-top: 20px;
}
}


@media (max-width: 1200px) {

.myescudo-away2{
  height: 100px;
  width: 100px;
  margin-top: -10px;
  margin-bottom: 10px;
margin-left: -48%;
}

 /* CSS that should be displayed if width is equal to or less than 800px goes here */
}



.caritem{

width:700px;
 height:400px;
}

@media (max-width: 1200px) {

.caritem{

width:100%;
 height:100%;
}

}
.lineup_home{
 font-size: 14px;
 font-weight: bold;
 text-align:center;
}
.lineup_away{
 font-size: 14px;
 font-weight: bold;
 text-align:center;
}


.pitch{

height: 690px;
  width: 480px;
text-align: center;
image-rendering: optimizespeed;
}

.new-line {
  white-space: pre-line;
}
.lineup{
 font-size: 18px;
 font-weight: bold;
 text-align:center;
}

.pitchdiv{
 text-align: center;
}
.titulo{
 text-align: center;
 font-weight: bold;

}


.escudo-home{
  height: 100px;
  width: 100px;
  margin-top: -10px;
  margin-bottom: 10px;
  text-align: center;
}

.escudo-away{
  height: 100px;
  width: 100px;
  margin-top: -10px;
  margin-bottom: 10px;
  text-align: center;
}


.escudoA{
text-align: center;
}

.ghome{
font-size: 30px;
font-weight: bold;

}
.mynewbutton{
    background-color: transparent;
    background-repeat: no-repeat;
    border: none;
    cursor: pointer;
    overflow: hidden;
    outline: none;
    color:white;
}
.mynewbutton:hover {
    color:#0056b3;
    text-decoration: underline;
}
.mynewbutton:focus{

	 outline:0 !important;
}
.match_card{
padding-left: 10px !important;
padding-right: 10px !important;

}

